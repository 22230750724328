<template>
  <div>
    <template>
      <div class="elevation-1">
        <table>
          <tr>
            <th>Event</th>
            <th>Action</th>
          </tr>
          <tr>
            <td>Shutdown</td>
            <td>
              <button @click="shutdown_email" type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary">
                <span class="v-btn__content">Send</span></button>
            </td>
          </tr>
          <tr>
            <td>Promotional</td>
            <td>
              <button @click="promotional_email" type="button" class="v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary">
                <span class="v-btn__content">Send</span></button>
            </td>
          </tr>
        </table>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  layout: 'admin_dashboard',
  props: {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    shutdown_email() {
      this.$api.get(`/shutdown/email`);
      this.$toast.success(
        `Successfully dispatched. Emails are sending in Background.`,
        { duration: 5000 }
      );
      return {'status': 'success'};
    },
    promotional_email() {
      this.$api.get(`/promotional/email`);
      this.$toast.success(
        `Successfully dispatched. Emails are sending in Background.`,
        { duration: 5000 }
      );
      return {'status': 'success'};
    }
  }
};
</script>
<style scoped>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.6);
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

td{
  color: #212529;
  font-weight: bold;
}
</style>
