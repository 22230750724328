<template>
  <div>
    <nav id="top-bar-notice" class="navbar fixed-top navbar-light navbar-expand-xl">
      <div class="notice-div">
        <p class="notice-text">MeterLeader is shutting down, the app will no longer be available on Dec 6, 2024</p>
      </div>
    </nav>
    <nav id="top-navbar" class="navbar fixed-top-1 navbar-light navbar-expand-xl">
      <nuxt-link to="/" class="navbar-brand">
        <img id="logo" src="~/assets/images/logo.svg" />
      </nuxt-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <div id="navbarNav" class="navbar-collapse collapse">
        <ul
          class="navbar-nav"
        >
          <li class="nav-item nav-item-decorate">
            <nuxt-link id="how" class="nav-link activeLinks" to="/how-it-works">
              How It Works
            </nuxt-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Who It's For
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <nuxt-link
                to="/cities"
                class="dropdown-item">Cities
              </nuxt-link>
              <nuxt-link
                to="/companies"
                class="dropdown-item">Companies
              </nuxt-link>
              <nuxt-link
                to="/ecogroups"
                class="dropdown-item">Environmental Groups
              </nuxt-link>
            </div>
          </li>
          <li class="nav-item nav-item-decorate">
            <nuxt-link
              id="explore"
              class="nav-link activeLinks"
              to="/explore-challenges"
            >
              Challenges
            </nuxt-link>
          </li>
<!--          <li id="signup-button" class="nav-item">-->
<!--            <nuxt-link class="nav-link" to="/challenges/create">-->
<!--              Create A Challenge-->
<!--            </nuxt-link>-->
<!--          </li>-->
<!--          <li id="signup-button" class="nav-item">-->
<!--            <nuxt-link class="nav-link" to="#">-->
<!--              Create A Challenge-->
<!--            </nuxt-link>-->
<!--          </li>-->
<!--          <li-->
<!--            class="nav-item"-->
<!--            style="border-left: 1px solid #4A4A4A; margin-left: 10px"-->
<!--          ></li>-->
<!--          <li class="nav-item nav-item-decorate">-->
<!--            <a class="nav-link" href="/login">Log In</a>-->
<!--          </li>-->
<!--          <li class="nav-item nav-item-decorate" style="font-weight: bold;">-->
<!--            <a class="nav-link" href="/signup">SIGN UP</a>-->
<!--          </li>-->
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  mounted() {
    if (process.client) {
      $(document).ready(function () {
        const navMain = $(".navbar-collapse");
        navMain.on("click", "a:not([data-toggle])", null, function () {
          navMain.collapse('hide');
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/assets/css/navbar';
</style>
