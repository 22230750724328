<template>
  <div>
    <EmailEvents></EmailEvents>
  </div>
</template>
<script>
import EmailEvents from '~/components/admind/EmailEvents.vue';

export default {
  layout: 'admin_dashboard',
  data() {
  },
  components: {
    EmailEvents,
  },
};
</script>
