<template>
  <div class="main container">
    <div
      class="section-1 d-flex flex-column align-items-center justify-content-center"
    >
      <div class="notice-div">
        <p class="notice-text">MeterLeader is shutting down, the app will no longer be available on Dec 6, 2024</p>
      </div>
      <img class="py-2" src="~/assets/images/logo-white.svg" />
      <h5 class="text-white font-weight-bold text-center">
        LETS SAVE ENERGY TOGETHER ⚡
      </h5>
      <h6 class="pt-3 text-center font-weight-bold">
        Track your Home's Electric & Gas Usage
        Participate in Energy Challenges
        <br>Reduce CO2 & Win Prizes!
      </h6>
    </div>

    <div class="section-2">
      <img class="banner-image" src="~/assets/images/Banner_MeterLeader.png" />
      <div
        class="d-flex flex-column align-items-center justify-content-center c-btn-grp"
      >
        <div v-if="!$auth.loggedIn" class="text-center py-2">
<!--          <button-->
<!--            class="cbtn py-2 font-weight-bold"-->
<!--            @click="$router.push('/signup')"-->
<!--          >-->
<!--            SIGN UP-->
<!--          </button>-->
<!--          <a class="cbtn py-2 font-weight-bold d-inline-block" href="/signup">SIGN UP</a>-->
<!--          <a class="cbtn py-2 font-weight-bold d-inline-block" href="#">SIGN UP</a>-->
        </div>
        <div v-if="!$auth.loggedIn" class="text-center py-2">
<!--          <button-->
<!--            class="cbtn py-2 font-weight-bold"-->
<!--            @click="$router.push('/login')"-->
<!--          >-->
<!--            LOG IN-->
<!--          </button>-->
<!--          <a class="cbtn py-2 font-weight-bold d-inline-block" href="/login">LOG IN</a>-->
<!--          <a class="cbtn py-2 font-weight-bold d-inline-block" href="#">LOG IN</a>-->
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  layout: 'empty',
};
</script>
  <style lang="sass" scoped>
@import '~@/assets/css/_variables.scss'
.main
  background: linear-gradient(to right, $accent, $primary)
  height: 100vh

.banner-image
  width: 100%
  object-fit: cover
  //margin-top: -35px

.cbtn
  border-radius: 30px
  width: 60vw
  background-color: #fff
  color: $accent

.section-1
  //height: 25vh
  height: 200px

.section-2
  //height: 73vh
  //margin-top: -35px
  & > img
    height: 70%
  & > div
    //min-height: 30%
    margin-top: -8px

.notice-div
  width: 100%
  text-align: center
  background-color: #ffffff

.notice-text
  color: red
  font-weight: bold
  padding-top: 25px
</style>
