<template>
  <div class="container">
<!--    <OnboardingReminder v-if="$auth.loggedIn" />-->
    <MobileLanding
      class="position-fixed mobile-landing d-block d-md-none"
    />
    <div class="d-none d-md-block">
      <div class="mbanner">
        <div class="row">
          <div
            class="
              col-12 col-md-6
              d-flex
              flex-column
              align-items-center align-items-md-start
              justify-content-center
              px-4
              pl-md-5
              mt-5 mt-md-0
            "
          >
            <h1 class="text-white font-weight-bold text-center text-md-left">
              Save Energy ⚡ <br />Fight Climate Change
            </h1>
            <h5 class="py-3 text-center text-md-left">
              Track your home’s electric, gas, and carbon emissions - in one convenient place.
<!--              Track your home’s electric, gas, and carbon emissions - in one convenient place. MeterLeader is integrated with energy data from <a style="color: inherit;" href="/faqs">18 utilities</a>-->
<!--              in 31 states.-->
            </h5>

<!--            <button-->
<!--              class="cbtn py-3 font-weight-bold"-->
<!--              @click="$router.push('/signup')"-->
<!--            >-->
<!--              GET STARTED FREE-->
<!--            </button>-->
            <button
              class="cbtn py-3 font-weight-bold"
            >
              GET STARTED FREE
            </button>

<!--            <img @click="downloadLink"-->
<!--              class="mt-3 download_link"-->
<!--              src="~/assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"-->
<!--            />-->
          </div>
          <div class="col-12 col-md-6">
            <img
              class="banner-image"
              src="~/assets/images/Banner_MeterLeader.png"
            />
          </div>
        </div>
      </div>
      <div class="msponsor">
        <div
          class="
            featured-in
            d-flex
            flex-column flex-md-row
            justify-content-center
            align-items-center
            pb-4
          "
        >
<!--          <h2 class="pr-4 font-weight-bold">As Featured In</h2>-->
          <div class="d-flex justify-content-center align-items-center">
            <a
              class="pr-md-4"
              href="https://www.softeq.com/news/softeq-venture-studio-welcomes-new-cohort-of-14-startups-bringing-total-venture-fund-portfolio-of-startups-to-63"
            >
              <img
                class="featured-img"
                style="max-height: 100px; object-fit: contain"
                src="~/assets/images/Softeq_Venture_Studio_Logo.jpg"
              />
            </a>
            <a
              class="pr-md-4"
              href="https://laincubator.org/laci-welcomes-10-promising-cleantech-startups-for-its-fall-2021-incubation-cohort/"
            >
              <img
                class="featured-img"
                style="max-height: 100px; object-fit: contain"
                src="~/assets/images/laciLogo.png"
              />
            </a>
            <a
              class="pr-md-4"
              href="https://www.techstars.com/newsroom/announcing-the-stanley-techstars-2022-class"
            >
              <img
                class="featured-img"
                style="max-height: 100px"
                src="~/assets/images/stanley-techstars-accelerator.png"
              />
            </a>
            <a href="https://www.netzeroaccelerator.org/nza-22">
              <img
                class="featured-img"
                style="max-height: 100px"
                src="~/assets/images/net_zero.png"
              />
            </a>
          </div>
        </div>
        <h1 class="d-none d-md-block text-center font-weight-bold pt-3 pb-4">
          Electricity and Heating Are Responsible For 1/3 Of <br />
          Global Carbon Emissions. We Can Change That!
        </h1>
      </div>
      <div class="how-it-works">
        <h1 class="text-center font-weight-bold">How It Works</h1>
        <div class="d-flex justify-content-center w-100">
          <div>
            <h2>⚡</h2>
            <h4 class="font-weight-bold">Sync Data</h4>
            <p>
              Sync your electricity and natural gas meter data automatically
              from your online utility account.
            </p>
          </div>
          <div>
            <h2>🏆</h2>
            <h4 class="font-weight-bold">Join Challenge</h4>
            <p>
              Engage in a fun competition to see who is the biggest energy
              saver.
            </p>
          </div>
          <div>
            <h2>🤩</h2>
            <h4 class="font-weight-bold">Save Energy</h4>
            <p>
              Energy tips help achieve measureable savings you can actually see
              via the real-time usage graph.
            </p>
          </div>
          <div>
            <h2>⭐</h2>
            <h4 class="font-weight-bold">Win Prizes</h4>
            <p>
              Households that save the most, win the most. Challenge winners
              qualify for cool prizes.
            </p>
          </div>
        </div>
        <div class="text-center font-weight-bold py-2">
          <a class="" href="#">Learn More</a>
        </div>
      </div>
<!--      <div class="explore-challenge">-->
<!--        <h1 class="text-center font-weight-bold">Explore Challenges</h1>-->
<!--        <div class="d-flex justify-content-center">-->
<!--          <div class="mx-3">-->
<!--            <img-->
<!--              id="lightning-img"-->
<!--              src="~/assets/images/SampleMeterLeaderChallenge.webp"-->
<!--            />-->
<!--            <div>-->
<!--              <h4 class="text-white mb-0 p-2">LASER Challenge</h4>-->
<!--              <p class="text-white text-vs p-2">-->
<!--                Starts: 05/05/2020 - Ends: 05/05/2020-->
<!--              </p>-->
<!--            </div>-->
<!--            <div class="d-flex align-items-center pb-4 mx-2">-->
<!--              <img-->
<!--                id="lightning-img"-->
<!--                src="~/assets/images/SampleMeterLeaderChallenge.webp"-->
<!--                class="avatar"-->
<!--              />-->
<!--              <p class="text-white text-vs px-2 mb-0">-->
<!--                Sponsored by: Meterleader-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="mx-3">-->
<!--            <img-->
<!--              id="lightning-img"-->
<!--              src="~/assets/images/SampleMeterLeaderChallenge.webp"-->
<!--            />-->
<!--            <div>-->
<!--              <h4 class="text-white mb-0 p-2">LASER Challenge</h4>-->
<!--              <p class="text-white text-vs p-2">-->
<!--                Starts: 05/05/2020 - Ends: 05/05/2020-->
<!--              </p>-->
<!--            </div>-->
<!--            <div class="d-flex align-items-center pb-4 mx-2">-->
<!--              <img-->
<!--                id="lightning-img"-->
<!--                src="~/assets/images/SampleMeterLeaderChallenge.webp"-->
<!--                class="avatar"-->
<!--              />-->
<!--              <p class="text-white text-vs px-2 mb-0">-->
<!--                Sponsored by: Meterleader-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="mx-3">-->
<!--            <img-->
<!--              id="lightning-img"-->
<!--              src="~/assets/images/SampleMeterLeaderChallenge.webp"-->
<!--            />-->
<!--            <div>-->
<!--              <h4 class="text-white mb-0 p-2">LASER Challenge</h4>-->
<!--              <p class="text-white text-vs p-2">-->
<!--                Starts: 05/05/2020 - Ends: 05/05/2020-->
<!--              </p>-->
<!--            </div>-->
<!--            <div class="d-flex align-items-center pb-4 mx-2">-->
<!--              <img-->
<!--                id="lightning-img"-->
<!--                src="~/assets/images/SampleMeterLeaderChallenge.webp"-->
<!--                class="avatar"-->
<!--              />-->
<!--              <p class="text-white text-vs px-2 mb-0">-->
<!--                Sponsored by: Meterleader-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="mx-3">-->
<!--            <img-->
<!--              id="lightning-img"-->
<!--              src="~/assets/images/SampleMeterLeaderChallenge.webp"-->
<!--            />-->
<!--            <div>-->
<!--              <h4 class="text-white mb-0 p-2">LASER Challenge</h4>-->
<!--              <p class="text-white text-vs p-2">-->
<!--                Starts: 05/05/2020 - Ends: 05/05/2020-->
<!--              </p>-->
<!--            </div>-->
<!--            <div class="d-flex align-items-center pb-4 mx-2">-->
<!--              <img-->
<!--                id="lightning-img"-->
<!--                src="~/assets/images/SampleMeterLeaderChallenge.webp"-->
<!--                class="avatar"-->
<!--              />-->
<!--              <p class="text-white text-vs px-2 mb-0">-->
<!--                Sponsored by: Meterleader-->
<!--              </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="text-center font-weight-bold pt-4 pb-2">-->
<!--          <a class="" href="#">Check More</a>-->
<!--        </div>-->
<!--      </div>-->
      <div class="why-meterleader">
        <h1 class="text-center font-weight-bold">Why Use Meterleader</h1>
        <div class="d-flex flex-column flex-md-row justify-content-center">
          <div class="text-center mx-3">
            <h3>✅</h3>
            <p>Less energy usage = savings on your utility bills!</p>
          </div>
          <div class="text-center mx-3">
            <h3>✅</h3>
            <p>Make positive environmental impacts that are close to home</p>
          </div>
          <div class="text-center mx-3">
            <h3>✅</h3>
            <p>
              Have Fun Participating In Our Community Driven Energy Saving
              Challenges
            </p>
          </div>
        </div>
      </div>

      <div class="why-save-energy">
        <h1 class="text-center font-weight-bold">Why Save Energy</h1>
        <div class="d-flex flex-column flex-md-row justify-content-center">
          <div class="text-md-center mx-3">
            <h3>💸</h3>
            <h1 class="text-p">$2060</h1>
            <p class="text-p">/Year</p>
            <p>
              You can save upwards of 30% on your utility bills by adopting
              energy saving habits and making affordable energy upgrades in your
              home.
            </p>
          </div>
          <div class="text-md-center mx-3">
            <h3>🤒</h3>
            <h1 class="text-p">230,000</h1>
            <p class="text-p">deaths/year</p>
            <p>
              Reducing your electricity usage can help combat negative effects
              of air pollution from power plants that contribute to respiratory
              diseases.
            </p>
          </div>
          <div class="text-md-center mx-3">
            <h3>☁️</h3>
            <h1 class="text-p">16.49</h1>
            <p class="text-p">metric tons CO2</p>
            <p>
              Combat climate change by taking small tangible steps to reduce
              your carbon footprint.
            </p>
          </div>
        </div>
      </div>
      <div class="bottom-banner d-flex align-items-center">
        <h2 class="text-white font-weight-bold">
          Save Energy And Combat Climate Change!
        </h2>
        <div :style="{ flexGrow: 1 }"></div>
<!--        <button-->
<!--          class="cbtn py-3 font-weight-bold"-->
<!--          @click="$router.push('/signup')"-->
<!--        >-->
<!--          GET STARTED FREE-->
<!--        </button>-->
        <button
          class="cbtn py-3 font-weight-bold"
        >
          GET STARTED FREE
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import FourSteps from '@/components/FourSteps';
import JoinFooter from '@/components/JoinFooter';
import Challenge from '@/components/Challenge';
import MobileLanding from '~/components/MobileLanding';
import { actionTypes } from '@/store/types';

export default {
  name: 'Home',
  components: {
    Challenge,
    JoinFooter,
    FourSteps,
    MobileLanding,
  },

  async asyncData({ store, app }) {
    try {
      await store.dispatch(actionTypes.GET_CHALLENGES);
    } catch (error) {
      console.log('error', error.response);
    }
  },
  data() {
    return {};
  },
  beforeMount() {
    if (this.$mobileDetect.mobile() || this.$mobileDetect.phone()) {
      if (this.$auth.loggedIn) {
        this.$router.push('/my-usage');
      }
    }
  },
  computed: {
    challenges() {
      if (process.client) {
        if (this.$store.state.challenges.length > 0) {
          this.$nextTick(function () {
            $('.challenge-homepage').hover(
              function () {
                $(this).find('.overlay').css('display', 'block');
              },
              function () {
                $(this).find('.overlay').css('display', 'none');
              }
            );
          });
          // TODO: do this properly
          return this.$store.state.challenges[1]
            .concat(this.$store.state.challenges[2])
            .slice(0, 3);
        }
      }
      return [];
    },
  },
  head() {
    return {
      title: "Let's Save Energy Together | MeterLeader",
      meta: [
        {
          hid: 'description',
          name: 'description',
          content:
            'MeterLeader motivates people to save energy through online challenges that are integrated with real-time electricity data. We make saving energy fun and meaningful.',
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: 'http://s3.amazonaws.com/meterleader-assets/fbimage.png',
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content:
            'MeterLeader motivates people to save energy through online challenges that are integrated with real-time electricity data. We make saving energy fun and meaningful.',
        },
        {
          hid: 'og:image:width',
          property: 'og:image:width',
          content: '876',
        },
        {
          hid: 'og:image:height',
          property: 'og:image:height',
          content: '451',
        },
      ],
    };
  },
  methods: {
    downloadLink() {
      window.location.href = "https://apps.apple.com/us/app/meterleader/id6443848802";
    }
  }
};
</script>
<style lang="sass" scoped>
@import '~@/assets/css/_variables.scss'

.text-vs
  font-size: 0.8rem

.text-p
  color: $primary

.mbanner
  background: linear-gradient(to right, $accent, $primary)
  border-radius: 20px
  margin: 30px 0px 30px 0px

.banner-image
  height: 100%
  width: 100%

.cbtn
  border-radius: 30px
  background-color: #fff
  width: 16rem
  color: $primary

.msponsor
  padding: 50px 0px 20px 0px
  h1
    background: linear-gradient(to right, $accent-600, $primary)
    -webkit-background-clip: text
    -webkit-text-fill-color: transparent
  a
    width: 30%

.featured-img
  width: 100%

.how-it-works
  background-color: $light-grey
  border-radius: 20px
  padding: 20px
  margin-top: 20px
  > h1
    color: $primary-600
    padding: 10px 0px 10px 0px
  > div
    flex-wrap: wrap
    > a
      color: $primary-600
    > div
      background-color: #fff
      border-radius: 10px
      margin: 20px
      padding: 15px 20px 15px 20px
      flex: 1

.avatar
  height: 40px
  width: 40px
  border-radius: 40px

.explore-challenge
  background: linear-gradient(to right, $accent-600, $primary)
  border-radius: 20px
  padding: 20px
  margin-top: 50px
  > h1
    color: #fff
    padding: 10px 0px 10px 0px
  > div
    flex-wrap: wrap
    > a
      color: #fff
    > div
      background: linear-gradient(to right, $primary-600, blue)
      border-radius: 10px
      flex: 1
      margin-bottom: 10px
      >img
        height: 100px
        object-fit: cover
        width: 100%
        border-radius: 10px 10px 0px 0px

.why-meterleader
  padding: 20px
  margin-top: 50px
  > h1
    padding: 10px 0px 10px 0px
  > div
    > div
      flex: 1

.why-save-energy
  background-color: $light-grey
  border-radius: 20px
  padding: 20px
  margin-top: 50px
  > h1
    padding: 10px 0px 10px 0px
  > div
    > div
      flex: 1

.bottom-banner
  background: linear-gradient(to right, $accent-600, $primary)
  border-radius: 20px
  padding: 50px
  margin-top: 50px
  margin-bottom: 50px

.download_link
  cursor: pointer
  width: 249px
  height: 50px
</style>
